import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { supabase } from "../../config/supabaseClient";

const PageWrapper = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  color: #333;
`;

const ArticleCard = styled.div`
  width: 100%;
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const Content = styled.pre`
  font-size: 1rem;
  margin-bottom: 10px;
  white-space: pre-wrap;
  font-family: inherit;
  overflow-wrap: break-word;
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 20rem;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
  justify-content: center;
  margin: 0 auto;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Header = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin: 10rem 0 2rem 0;
  font-family: "Paris avenue";
`;

const PageButton = styled.button`
  background-color: #e2c7a6;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 5px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #bc9f8b;
  }
`;

const ArticlesList = () => {
  const [articles, setArticles] = useState([]);
  const [signedUrls, setSignedUrls] = useState({});
  const [page, setPage] = useState(1);
  const [totalArticles, setTotalArticles] = useState(0);
  const articlesPerPage = 1;

  // Fetch total number of articles
  useEffect(() => {
    const fetchTotalArticles = async () => {
      const { count, error } = await supabase
        .from("articlesdb")
        .select("*", { count: "exact", head: true });

      if (error) {
        console.error("Error fetching total articles count:", error);
      } else {
        setTotalArticles(count);
      }
    };

    fetchTotalArticles();
  }, []);

  // Fetch articles for the current page
  useEffect(() => {
    const fetchArticles = async () => {
      const { data, error } = await supabase
        .from("articlesdb")
        .select("*")
        .range((page - 1) * articlesPerPage, page * articlesPerPage - 1);

      console.log(data);
      if (error) {
        console.error("Error fetching articles:", error);
      } else {
        setArticles(data);
      }
    };

    fetchArticles();
  }, [page]);

  // Fetch signed URLs for images
  useEffect(() => {
    const fetchSignedUrls = async () => {
      const urls = {};
      for (const article of articles) {
        const { data: signedUrlData, error } = await supabase.storage
          .from("articlestorage")
          .createSignedUrl(article.image_link, 60);

        if (error) {
          console.error("Error getting signed URL:", error);
        } else {
          urls[article.id] = signedUrlData.signedUrl;
        }
      }
      setSignedUrls(urls);
    };

    if (articles.length > 0) {
      fetchSignedUrls();
    }
  }, [articles]);

  const totalPages = Math.ceil(totalArticles / articlesPerPage);

  return (
    <PageWrapper>
      <Header>Articles</Header>
      <div>
        {articles.map((article) => (
          <ArticleCard key={article.id}>
            <Title>{article.title}</Title>
            {signedUrls[article.id] && (
              <StyledImage src={signedUrls[article.id]} alt={article.title} />
            )}
            <Content>{article.content}</Content>
            <Content>{article.references}</Content>
          </ArticleCard>
        ))}
        <Pagination>
          <PageButton onClick={() => setPage(page - 1)} disabled={page === 1}>
            Previous
          </PageButton>
          <PageButton
            onClick={() => setPage(page + 1)}
            disabled={page === totalPages}
          >
            Next
          </PageButton>
        </Pagination>
      </div>
    </PageWrapper>
  );
};

export default ArticlesList;
