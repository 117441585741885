import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Modal } from "@mantine/core";
import { IconX } from "@tabler/icons-react";

const Container = styled.div`
  max-width: 400px;
  margin: 4rem auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  font-family: "Paris Avenue";
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const SubmitButton = styled.button`
  background-color: #e2c7a6;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #bc9f8b;
  }
`;

const ErrorModal = styled(Modal)`
  .mantine-Modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
  }
`;

function Login() {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const adminUsername = process.env.REACT_APP_ADMIN_USERNAME;
    const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD;

    if (
      credentials.username === adminUsername &&
      credentials.password === adminPassword
    ) {
      // Set authentication in localStorage
      localStorage.setItem("isAuthenticated", "true");
      // Navigate to the previous protected route or home
      navigate(from, { replace: true });
    } else {
      setShowError(true);
    }
  };

  return (
    <Container style={{ paddingTop: "10rem" }}>
      <Title>Admin Login</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="username"
          placeholder="Username"
          value={credentials.username}
          onChange={handleChange}
          required
        />
        <Input
          type="password"
          name="password"
          placeholder="Password"
          value={credentials.password}
          onChange={handleChange}
          required
        />
        <SubmitButton type="submit">Login</SubmitButton>
      </Form>

      <ErrorModal
        opened={showError}
        onClose={() => setShowError(false)}
        title="Error"
        centered
      >
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <IconX size={24} color="red" />
          <p>Invalid credentials. Please try again.</p>
        </div>
      </ErrorModal>
    </Container>
  );
}

export default Login;
