import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { supabase } from "../../config/supabaseClient";
import { Link } from "react-router-dom";

const PageWrapper = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  min-height: 100vh;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const ArticleCard = styled(Link)`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
  text-decoration: none;
  color: inherit;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 1rem;
`;

const CardTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #333;
`;

const CardExcerpt = styled.div`
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
  max-height: 4.5em;
  overflow: hidden;
  position: relative;

  /* Add ellipsis at the end */
  &:after {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
    padding-left: 1rem;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%);
  }

  /* Styling for rich text content */
  p {
    margin: 0.3em 0;
  }

  a,
  .article-link {
    color: #e2c7a6;
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0.5em 0 0.3em;
    font-size: 1em;
    font-weight: 600;
  }

  ul,
  ol {
    padding-left: 1.5em;
    margin: 0.3em 0;
  }
`;

const Header = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin: 10rem 0 2rem 0;
  font-family: "Paris avenue";
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const PageButton = styled.button`
  background-color: #e2c7a6;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #bc9f8b;
  }
`;

const ArticleGrid = () => {
  const [articles, setArticles] = useState([]);
  const [signedUrls, setSignedUrls] = useState({});
  const [page, setPage] = useState(1);
  const [totalArticles, setTotalArticles] = useState(0);
  const articlesPerPage = 6;

  // Fetch total number of articles
  useEffect(() => {
    const fetchTotalArticles = async () => {
      const { count, error } = await supabase
        .from("articlesdb")
        .select("*", { count: "exact", head: true });

      if (error) {
        console.error("Error fetching total articles count:", error);
      } else {
        setTotalArticles(count);
      }
    };

    fetchTotalArticles();
  }, []);

  // Fetch articles for the current page
  useEffect(() => {
    const fetchArticles = async () => {
      const { data, error } = await supabase
        .from("articlesdb")
        .select("*")
        .order("created_at", { ascending: false })
        .range((page - 1) * articlesPerPage, page * articlesPerPage - 1);

      if (error) {
        console.error("Error fetching articles:", error);
      } else {
        setArticles(data);
      }
    };

    fetchArticles();
  }, [page]);

  // Fetch signed URLs for images
  useEffect(() => {
    const fetchSignedUrls = async () => {
      const urls = {};
      for (const article of articles) {
        const { data: signedUrlData, error } = await supabase.storage
          .from("articlestorage")
          .createSignedUrl(article.image_link, 60);

        if (error) {
          console.error("Error getting signed URL:", error);
        } else {
          urls[article.id] = signedUrlData.signedUrl;
        }
      }
      setSignedUrls(urls);
    };

    if (articles.length > 0) {
      fetchSignedUrls();
    }
  }, [articles]);

  const totalPages = Math.ceil(totalArticles / articlesPerPage);

  // A function to strip HTML tags for text-only excerpt
  const createExcerpt = (htmlContent, maxLength = 150) => {
    // Create a temporary element to parse HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Get text content only
    const textContent = tempDiv.textContent || tempDiv.innerText || "";

    // Limit the length
    return textContent.length > maxLength
      ? textContent.substring(0, maxLength)
      : textContent;
  };

  return (
    <PageWrapper>
      <Header>Articles</Header>
      <GridContainer>
        {articles.map((article) => (
          <ArticleCard key={article.id} to={`/article/${article.id}`}>
            {signedUrls[article.id] && (
              <CardImage src={signedUrls[article.id]} alt={article.title} />
            )}
            <CardContent>
              <CardTitle>{article.title}</CardTitle>
              <CardExcerpt>
                <div
                  dangerouslySetInnerHTML={{
                    __html: article.content.split("</p>")[0] + "</p>",
                  }}
                />
              </CardExcerpt>
            </CardContent>
          </ArticleCard>
        ))}
      </GridContainer>
      <Pagination>
        <PageButton onClick={() => setPage(page - 1)} disabled={page === 1}>
          Previous
        </PageButton>
        <span>
          Page {page} of {totalPages}
        </span>
        <PageButton
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </PageButton>
      </Pagination>
    </PageWrapper>
  );
};

export default ArticleGrid;
