import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text, Button, Avatar, Group, Stack, Paper } from "@mantine/core";
import { supabase } from "../config/supabaseClient";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem;
  @media (min-width: 868px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 10rem;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  @media (min-width: 868px) {
    margin-bottom: 0;
  }
`;

const RightColumn = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 60px;
  height: 50px;
  border-radius: 20px;
  margin: 0 1rem;
  background-color: ${(props) =>
    props.isHovered ? "#e2c7a6 !important" : "transparent"};
  color: ${(props) => (props.isHovered ? "white" : "black")};
  border-color: ${(props) => (props.isHovered ? "violet" : "black")};
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
`;

// Simple loading indicator component
const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  font-size: 1.2rem;
  color: #666;
`;

function Testimonials() {
  const [isHoveredLeft, setIsHoveredLeft] = useState(false);
  const [isHoveredRight, setIsHoveredRight] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Default avatar images - used if we don't have custom ones in the DB
  const defaultAvatars = [
    "https://randomuser.me/api/portraits/men/32.jpg",
    "https://randomuser.me/api/portraits/women/44.jpg",
    "https://randomuser.me/api/portraits/men/34.jpg",
    "https://randomuser.me/api/portraits/women/68.jpg",
  ];

  // Fetch testimonials from Supabase
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        setLoading(true);

        const { data, error } = await supabase
          .from("testimonials")
          .select("*")
          .order("created_at", { ascending: false });

        if (error) {
          throw error;
        }

        if (data && data.length > 0) {
          // Map the data to include avatars
          const testimonialsWithAvatars = data.map((testimonial, index) => ({
            ...testimonial,
            avatar:
              testimonial.avatar ||
              defaultAvatars[index % defaultAvatars.length],
            position: testimonial.position || "Client", // Default position if not in DB
          }));

          setTestimonials(testimonialsWithAvatars);
        } else {
          // Fallback to default testimonials if no data
          setTestimonials([
            {
              id: 1,
              message: `I had an amazing experience working with Embark Properties. From the initial consultation to closing, the team was professional, responsive, and incredibly knowledgeable. They found me the perfect villa within my budget and handled all the paperwork seamlessly. Highly recommend for anyone looking for personalized and attentive service!`,
              author: "Ken Adams",
              position: "client",
              avatar: "https://randomuser.me/api/portraits/men/32.jpg",
            },
            {
              id: 2,
              message: `I contacted Embark Properties for assistance in finding an industrial property for my logistics business. Their understanding of the market and attention to my requirements was outstanding. They provided a range of options and helped negotiate a deal that fit perfectly with my business needs. I couldn't be more satisfied with their service.`,
              author: "John Doe",
              position: "client",
              avatar: "https://randomuser.me/api/portraits/men/34.jpg",
            },
          ]);
        }
      } catch (err) {
        console.error("Error fetching testimonials:", err);
        setError("Failed to load testimonials");
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Show loading indicator while fetching data
  if (loading) {
    return (
      <Container id="people">
        <LeftColumn>
          <Text
            weight={700}
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
          >
            Why
          </Text>
          <Text
            weight={700}
            style={{
              fontSize: "3rem",
              fontWeight: "500",
              fontFamily: "Paris Avenue",
              marginTop: "-1rem",
            }}
          >
            Embark
          </Text>
          <Text
            weight={700}
            style={{
              fontSize: "3rem",
              fontWeight: "500",
              fontFamily: "Paris Avenue",
              marginTop: "-2rem",
            }}
          >
            Properties
          </Text>
        </LeftColumn>
        <RightColumn>
          <LoadingIndicator>Loading testimonials...</LoadingIndicator>
        </RightColumn>
      </Container>
    );
  }

  // Show error message if fetching failed
  if (error || testimonials.length === 0) {
    return (
      <Container id="people">
        <LeftColumn>
          <Text
            weight={700}
            style={{
              fontSize: "2rem",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
          >
            Why
          </Text>
          <Text
            weight={700}
            style={{
              fontSize: "3rem",
              fontWeight: "500",
              fontFamily: "Paris Avenue",
              marginTop: "-1rem",
            }}
          >
            Embark
          </Text>
          <Text
            weight={700}
            style={{
              fontSize: "3rem",
              fontWeight: "500",
              fontFamily: "Paris Avenue",
              marginTop: "-2rem",
            }}
          >
            Properties
          </Text>
        </LeftColumn>
        <RightColumn>
          <Paper
            shadow="sm"
            radius="md"
            style={{
              width: "100%",
              height: "100%",
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "1.5rem",
            }}
          >
            <Text size="md" color="dimmed">
              {error || "No testimonials available at the moment."}
            </Text>
          </Paper>
        </RightColumn>
      </Container>
    );
  }

  return (
    <Container id="people">
      <LeftColumn>
        <Text
          weight={700}
          style={{ fontSize: "2rem", fontWeight: "500", fontFamily: "Poppins" }}
        >
          Why
        </Text>
        <Text
          weight={700}
          style={{
            fontSize: "3rem",
            fontWeight: "500",
            fontFamily: "Paris Avenue",
            marginTop: "-1rem",
          }}
        >
          Embark
        </Text>
        <Text
          weight={700}
          style={{
            fontSize: "3rem",
            fontWeight: "500",
            fontFamily: "Paris Avenue",
            marginTop: "-2rem",
          }}
        >
          Properties
        </Text>
      </LeftColumn>
      <RightColumn>
        <StyledButton
          onClick={handlePrev}
          onMouseEnter={() => setIsHoveredLeft(true)}
          onMouseLeave={() => setIsHoveredLeft(false)}
          isHovered={isHoveredLeft}
          disabled={testimonials.length <= 1}
        >
          <i className="fa fa-arrow-left"></i>
        </StyledButton>
        <Paper
          shadow="sm"
          radius="md"
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid black",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1.5rem",
          }}
        >
          <Text
            size="sm"
            style={{
              color: "#464545",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "clamp(0.875rem, 0.8rem + 0.5vw, 1.2rem)",
              lineHeight: "1.6",
              "@media (max-width: 768px)": {
                fontSize: "1rem",
              },
              "@media (max-width: 480px)": {
                fontSize: "0.875rem",
              },
            }}
          >
            "{testimonials[currentIndex].message}"
          </Text>
          <Group mt="md" style={{ alignSelf: "flex-start" }}>
            <Avatar
              src={testimonials[currentIndex].avatar}
              alt={testimonials[currentIndex].author}
            />
            <Stack spacing={0} style={{ gap: "0" }}>
              <Text size="sm" weight={500}>
                {testimonials[currentIndex].author}
              </Text>
              <Text size="xs" color="gray">
                {testimonials[currentIndex].position || "Client"}
              </Text>
            </Stack>
          </Group>
        </Paper>
        <StyledButton
          onClick={handleNext}
          onMouseEnter={() => setIsHoveredRight(true)}
          onMouseLeave={() => setIsHoveredRight(false)}
          isHovered={isHoveredRight}
          disabled={testimonials.length <= 1}
        >
          <i className="fa fa-arrow-right"></i>
        </StyledButton>
      </RightColumn>
    </Container>
  );
}

export default Testimonials;
