import React, { useState, useRef } from "react";
import styled from "styled-components";
import { supabase } from "../../config/supabaseClient";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import { EditorProvider } from "@tiptap/react";

const PageWrapper = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  color: #333;
`;

const FormWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Paris Avenue";
`;

const InputField = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;

  &:focus {
    border-color: #e2c7a6;
    outline: none;
  }
`;

const TextAreaField = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  resize: none;
  white-space: pre-wrap;

  &:focus {
    border-color: #e2c7a6;
    outline: none;
  }
`;

const SubmitButton = styled.button`
  background-color: #e2c7a6;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #bc9f8b;
  }
`;

// Add new styled component for loader
const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #e2c7a6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const EditorContainer = styled.div`
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;

  .ProseMirror {
    padding: 0.75rem;
    min-height: 150px;
    outline: none;

    p {
      margin: 0.5em 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 1em 0 0.5em;
    }

    ul,
    ol {
      padding-left: 1.5em;
    }

    &:focus {
      border-color: #e2c7a6;
    }
  }
`;

const MenuBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
  background-color: #f5f5f5;

  button {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    padding: 0.25rem 0.5rem;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }

    &.is-active {
      background-color: #e2c7a6;
      color: white;
    }
  }
`;

const LinkInput = styled.div`
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  align-items: center;
  margin: 0.5rem;

  input {
    flex: 1;
    padding: 0.25rem 0.5rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 0.5rem;
  }

  button {
    padding: 0.25rem 0.5rem;
    background-color: #e2c7a6;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: #bc9f8b;
    }
  }
`;

const Tiptap = ({ onChange, content }) => {
  const [linkUrl, setLinkUrl] = useState("");
  const [showLinkInput, setShowLinkInput] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          class: "article-link",
        },
      }),
    ],
    content: content || "",
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  const setLink = () => {
    if (!editor) return;

    // Check if text is selected
    if (editor.state.selection.empty) {
      alert("Please select some text first");
      return;
    }

    // If URL is provided
    if (linkUrl) {
      // Add https:// if protocol is missing
      const url = linkUrl.trim().startsWith("http")
        ? linkUrl.trim()
        : `https://${linkUrl.trim()}`;

      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();

      setLinkUrl("");
      setShowLinkInput(false);
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <EditorContainer>
      <MenuBar>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          Bold
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          Italic
        </button>
        <button
          onClick={() => {
            // Only apply heading if text is selected, otherwise do nothing
            if (!editor.state.selection.empty) {
              editor.chain().focus().toggleHeading({ level: 1 }).run();
            } else {
              alert("Please select some text first");
            }
          }}
          className={
            editor.isActive("heading", { level: 1 }) ? "is-active" : ""
          }
        >
          H1
        </button>
        <button
          onClick={() => {
            // Only apply heading if text is selected, otherwise do nothing
            if (!editor.state.selection.empty) {
              editor.chain().focus().toggleHeading({ level: 2 }).run();
            } else {
              alert("Please select some text first");
            }
          }}
          className={
            editor.isActive("heading", { level: 2 }) ? "is-active" : ""
          }
        >
          H2
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          Bullet List
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          Ordered List
        </button>
        <button
          onClick={() => setShowLinkInput(!showLinkInput)}
          className={editor.isActive("link") ? "is-active" : ""}
        >
          Link
        </button>
        {editor.isActive("link") && (
          <button onClick={() => editor.chain().focus().unsetLink().run()}>
            Unlink
          </button>
        )}
      </MenuBar>
      <LinkInput isVisible={showLinkInput}>
        <input
          type="text"
          placeholder="Enter URL"
          value={linkUrl}
          onChange={(e) => setLinkUrl(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setLink();
            }
          }}
        />
        <button onClick={setLink}>Add Link</button>
      </LinkInput>
      <EditorContent editor={editor} />
    </EditorContainer>
  );
};

// Simplified TipTap editor component for references
const ReferencesEditor = ({ onChange, content }) => {
  const [linkUrl, setLinkUrl] = useState("");
  const [showLinkInput, setShowLinkInput] = useState(false);

  const editor = useEditor({
    extensions: [
      // Only include paragraph, text, and link from StarterKit
      StarterKit.configure({
        heading: false,
        bold: false,
        italic: false,
        bulletList: false,
        orderedList: false,
        blockquote: false,
        code: false,
        codeBlock: false,
        horizontalRule: false,
      }),
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          class: "reference-link",
        },
      }),
    ],
    content: content || "",
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  const setLink = () => {
    if (!editor) return;

    // Check if text is selected
    if (editor.state.selection.empty) {
      alert("Please select some text first");
      return;
    }

    // If URL is provided
    if (linkUrl) {
      // Add https:// if protocol is missing
      const url = linkUrl.trim().startsWith("http")
        ? linkUrl.trim()
        : `https://${linkUrl.trim()}`;

      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();

      setLinkUrl("");
      setShowLinkInput(false);
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <EditorContainer>
      <MenuBar>
        <button
          onClick={() => setShowLinkInput(!showLinkInput)}
          className={editor.isActive("link") ? "is-active" : ""}
        >
          Link
        </button>
        {editor.isActive("link") && (
          <button onClick={() => editor.chain().focus().unsetLink().run()}>
            Unlink
          </button>
        )}
      </MenuBar>
      <LinkInput isVisible={showLinkInput}>
        <input
          type="text"
          placeholder="Enter URL"
          value={linkUrl}
          onChange={(e) => setLinkUrl(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setLink();
            }
          }}
        />
        <button onClick={setLink}>Add Link</button>
      </LinkInput>
      <EditorContent editor={editor} />
    </EditorContainer>
  );
};

const UploadArticle = () => {
  // Add loading state
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    content: "",
    references: "",
    image: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleContentChange = (html) => {
    setFormData({
      ...formData,
      content: html,
    });
  };

  const handleReferencesChange = (html) => {
    setFormData({
      ...formData,
      references: html,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const { title, content, references, image } = formData;

    if (!image) {
      console.error("No image selected");
      return;
    }

    // Generate a unique file name
    const fileExtension = image.name.split(".").pop(); // Get the file extension
    const uniqueName = `${Date.now()}-${Math.random()
      .toString(36)
      .substring(2, 9)}.${fileExtension}`;

    try {
      // Upload image to Supabase storage
      const { data: imageData, error: imageError } = await supabase.storage
        .from("articlestorage") // Ensure this matches the bucket name exactly
        .upload(uniqueName, image);

      if (imageError) {
        console.error("Error uploading image:", imageError);
        return;
      } else {
        console.log("Image uploaded successfully:", imageData);
      }

      // Insert form data into the articlesdb table
      const { data, error } = await supabase.from("articlesdb").insert([
        {
          title,
          image_link: imageData.path,
          content,
          references,
        },
      ]);

      if (error) {
        console.error("Error inserting data:", error);
      } else {
        console.log("Data inserted successfully:", data);
      }

      // Clear form after successful submission
      setFormData({
        title: "",
        content: "",
        references: "",
        image: null,
      });

      // Reset file input
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) fileInput.value = "";
    } catch (err) {
      console.error("Unexpected error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageWrapper style={{ paddingTop: "10rem" }}>
      <FormWrapper>
        <Title>Upload Article</Title>
        <form onSubmit={handleSubmit}>
          <InputField
            type="text"
            name="title"
            placeholder="Article Title"
            value={formData.title}
            onChange={handleChange}
            required
          />
          <label>Article Content</label>
          <Tiptap onChange={handleContentChange} content={formData.content} />
          <label>Article References</label>
          <ReferencesEditor
            onChange={handleReferencesChange}
            content={formData.references}
          />
          <InputField
            type="file"
            name="image"
            onChange={handleChange}
            required
          />
          {isLoading ? (
            <Loader />
          ) : (
            <SubmitButton type="submit">Submit</SubmitButton>
          )}
        </form>
      </FormWrapper>
    </PageWrapper>
  );
};

export default UploadArticle;
