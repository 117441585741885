import React, { useState, useEffect, useRef } from "react";
import { Text } from "@mantine/core"; // Keeping Mantine Text for typography if needed
import logo from "../assets/images/Icon.png"; // Replace with your actual logo path
import InfoImage from "../assets/images/infoImage2PNG.png";
import InfoImageNew from "../assets/images/info-image-new.jpg";
import InfoImageNew2 from "../assets/images/info-image-new-2.jpg";
import styled from "styled-components";

// Styled component for the main container
const Container = styled.div`
  margin: 5rem 10rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin: 2rem 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;

// Styled component for the image and content wrapper
const ImageAndTextWrapper = styled.div`
  display: flex;
  margin: 3rem 5%;
  flex-direction: row;
  gap: 2.3rem;

  @media (max-width: 768px) {
    margin: 1rem 5%;
    flex-direction: column;
    gap: 0rem;
  }
`;

// Flexbox container for the image
const ImageContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-right: 0;
  }

  img {
    width: auto;
    height: 25rem;
    border-radius: 1rem;
    max-width: 100%;
    object-fit: contain;
    transform: ${(props) =>
      `translateY(-${props.scrollY * 0.05}px)`}; // Parallax effect
    transition: transform 0.1s ease-out; // Smooth transition
  }
`;

// Flexbox container for the text
const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-right: 2rem;

  @media (max-width: 768px) {
    align-items: center;
    margin: 0;
    width: -webkit-fill-available;
    text-align: center;
    gap: 1;
  }
`;

// Styled component for the logo
const RotatingLogo = styled.div`
  position: relative;
  width: 7rem;
  height: 7rem;
  aspect-ratio: 1/1;

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #131313;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 5s linear infinite; // CSS animation for rotation

    img {
      width: 3.5rem;
      height: 3.5rem;
      object-fit: contain;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const HighlightText = styled.div`
  @media (max-width: 768px) {
    font-size: 0.3rem !important;
  }
`;

function Information() {
  const [scrollY, setScrollY] = useState(0);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const textRefs = useRef([]);

  // Handle the scroll event to move the image
  const handleScroll = () => {
    setScrollY(window.scrollY);

    // Define the slack (offset) to give more room
    const slack = -300;

    // Check which text is in view
    textRefs.current.forEach((ref, index) => {
      if (ref) {
        const rect = ref.getBoundingClientRect();
        const top = rect.top - slack;
        const bottom = rect.bottom + slack;

        if (top <= window.innerHeight && bottom >= 0) {
          setHighlightedIndex(index);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="intro">
      <Container>
        <div>
          <Text style={{ fontSize: "3rem", fontFamily: "Poppins" }}>
            Discovering the{" "}
            <span style={{ fontFamily: "Paris Avenue", fontWeight: "600" }}>
              Perfect Property
            </span>{" "}
            for You.
          </Text>
          {/* <Text
            style={{
              fontSize: "3rem",
              fontFamily: "Poppins",
              marginTop: "-1.75rem",
            }}
          >
            for You.
          </Text> */}
        </div>

        {/* <RotatingLogo>
          <div>
            <img src={logo} alt="Logo" />
          </div>
        </RotatingLogo> */}
      </Container>

      <ImageAndTextWrapper>
        <ImageContainer scrollY={scrollY}>
          <img src={InfoImageNew2} alt="PropertyImage" />
        </ImageContainer>

        <TextContainer>
          <HighlightText>
            <Text
              ref={(el) => (textRefs.current[0] = el)}
              style={{
                fontSize: highlightedIndex === 0 ? "1.5rem" : "1.5rem",
                fontFamily: "Poppins",
                marginBottom: "4rem",
                fontWeight: highlightedIndex === 0 ? "500" : "",
                color: highlightedIndex === 0 ? "black" : "#e2c7a6",
                transition: "all 0.2s ease-out",
              }}
            >
              Tailored Brokerage Solutions
            </Text>
          </HighlightText>
          <Text
            ref={(el) => (textRefs.current[1] = el)}
            style={{
              fontSize: highlightedIndex === 1 ? "1.5rem" : "1.5rem",
              fontFamily: "Poppins",
              marginBottom: "4rem",
              fontWeight: highlightedIndex === 1 ? "500" : "",
              color: highlightedIndex === 1 ? "black" : "#e2c7a6",
              transition: "all 0.2s ease-out",
            }}
          >
            Expert Property Advisory
          </Text>
          <Text
            ref={(el) => (textRefs.current[2] = el)}
            style={{
              fontSize: highlightedIndex === 2 ? "1.5rem" : "1.5rem",
              fontFamily: "Poppins",
              marginBottom: "4rem",
              fontWeight: highlightedIndex === 2 ? "500" : "",
              color: highlightedIndex === 2 ? "black" : "#e2c7a6",
              transition: "all 0.2s ease-out",
            }}
          >
            Market Analysis
          </Text>
          <Text
            ref={(el) => (textRefs.current[3] = el)}
            style={{
              fontSize: highlightedIndex === 3 ? "1.5rem" : "1.5rem",
              fontFamily: "Poppins",
              marginBottom: "4rem",
              fontWeight: highlightedIndex === 3 ? "500" : "",
              color: highlightedIndex === 3 ? "black" : "#e2c7a6",
              transition: "all 0.2s ease-out",
            }}
          >
            Legal Assistance
          </Text>
        </TextContainer>
      </ImageAndTextWrapper>
    </div>
  );
}

export default Information;
