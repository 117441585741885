import React, { useState } from "react";
import styled from "styled-components";
import { supabase } from "../../config/supabaseClient";
import { Modal } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  font-family: "Paris Avenue";
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const TextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  min-height: 150px;
`;

const SubmitButton = styled.button`
  background-color: #e2c7a6;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #bc9f8b;
  }
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
`;

const ModalDescription = styled.p`
  font-size: 16px;
  color: #777;
  margin-bottom: 1.5rem;
`;

function UploadTestimonial() {
  const [formData, setFormData] = useState({
    author: "",
    message: "",
  });
  const [modalOpened, setModalOpened] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { error } = await supabase.from("testimonials").insert([formData]);

      if (error) throw error;

      // Clear form and show success modal
      setFormData({ author: "", message: "" });
      setModalOpened(true);
    } catch (error) {
      console.error("Error submitting testimonial:", error);
      alert("Error submitting testimonial. Please try again.");
    }
  };

  return (
    <Container style={{ paddingTop: "10rem" }}>
      <Title>Upload Testimonial</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="author"
          placeholder="Author Name"
          value={formData.author}
          onChange={handleChange}
          required
        />
        <TextArea
          name="message"
          placeholder="Testimonial Message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <SubmitButton type="submit">Submit Testimonial</SubmitButton>
      </Form>

      <Modal opened={modalOpened} onClose={() => setModalOpened(false)}>
        <StyledModalContent>
          <IconCheck size={48} color="#6bc47d" />
          <ModalTitle>Thank You!</ModalTitle>
          <ModalDescription>
            The testimonial has been uploaded successfully.
          </ModalDescription>
        </StyledModalContent>
      </Modal>
    </Container>
  );
}

export default UploadTestimonial;
