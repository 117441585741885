import "./App.css";
import NavBar from "./toolComponents/NavBar/NavBar";
import Footer from "./toolComponents/Footer/Footer";

import Landing from "./pages/Landing/Landing";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";

import Information from "./components/information";
import Services from "./components/services";
import Founders from "./components/founders";
import Testimonials from "./components/testimonials";
import Career from "./pages/Career/Career";
import ServicesPage from "./pages/Services/ServicesPage";
import UploadArticle from "./pages/Upload_Articles/UploadArticle";
import ArticlesList from "./pages/ArticlesList/ArticlesList";
import ArticleGrid from "./pages/ArticleGrid/ArticleGrid";
import "@mantine/core/styles.css";

import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import styled from "styled-components";

import "@mantine/core/styles.css";
import { MantineProvider } from "@mantine/core";
import Whyus from "./components/whyus";
import Brands from "./components/brands";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ArticleView from "./pages/ArticleView/ArticleView";
import UploadTestimonial from "./pages/UploadTestimonial/UploadTestimonial";
import Login from "./pages/Login/Login";
import ProtectedRoute from "./components/ProtectedRoute";

const Container = styled.div`
  padding: 2rem;
`;

const Pages = () => {
  return (
    <div>
      <NavBar />
      {/* <div style={{ height: "3rem", width: "100%" }}></div> */}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/career" element={<Career />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/uploadarticle"
          element={
            <ProtectedRoute>
              <UploadArticle />
            </ProtectedRoute>
          }
        />
        <Route path="/articleslist" element={<ArticlesList />} />
        <Route path="/article/:id" element={<ArticleView />} />
        <Route path="/articles" element={<ArticleGrid />} />
        <Route
          path="/uploadtestimonial"
          element={
            <ProtectedRoute>
              <UploadTestimonial />
            </ProtectedRoute>
          }
        />
      </Routes>

      <Footer />
    </div>
  );
};

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      {" "}
      {/* Wrapping the app */}
      <Router>
        <Pages />
      </Router>
    </MantineProvider>
  );
}

export default App;
