import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import { supabase } from "../../config/supabaseClient";

const PageWrapper = styled.div`
  padding: 40px 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
`;

const ArticleContainer = styled.article`
  max-width: 80%;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 2rem;
  margin-top: 8rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
  font-family: "Poppins";
`;

const Image = styled.img`
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const Content = styled.div`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #444;
  margin-bottom: 2rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.2em 0 0.6em;
    font-weight: 600;
  }

  p {
    margin: 0.7em 0;
  }

  ul,
  ol {
    padding-left: 1.5em;
    margin: 0.7em 0;
  }

  blockquote {
    border-left: 3px solid #e2c7a6;
    padding-left: 1em;
    margin-left: 0;
    font-style: italic;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  code {
    background-color: #f0f0f0;
    padding: 0.1em 0.3em;
    border-radius: 3px;
    font-family: monospace;
  }

  a,
  .article-link {
    color: #e2c7a6;
    text-decoration: underline;
    transition: color 0.2s;

    &:hover {
      color: #bc9f8b;
    }
  }
`;

const References = styled.div`
  font-size: 1rem;
  color: #666;
  border-top: 1px solid #eee;
  padding-top: 1.5rem;
  margin-top: 2rem;

  p {
    margin: 0.5em 0;
  }

  a,
  .reference-link {
    color: #e2c7a6;
    text-decoration: underline;
    transition: color 0.2s;

    &:hover {
      color: #bc9f8b;
    }
  }
`;

const BackButton = styled(Link)`
  display: inline-block;
  background-color: #e2c7a6;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  margin-bottom: 2rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #bc9f8b;
  }
`;

const ReferencesTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  font-family: "Poppins";
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #e2c7a6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
`;

const ArticleView = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [signedUrl, setSignedUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        // Fetch article data
        const { data, error } = await supabase
          .from("articlesdb")
          .select("*")
          .eq("id", id)
          .single();

        if (error) throw error;

        if (data) {
          setArticle(data);

          // Fetch signed URL for image
          const { data: signedUrlData, error: signedUrlError } =
            await supabase.storage
              .from("articlestorage")
              .createSignedUrl(data.image_link, 60);

          if (signedUrlError) throw signedUrlError;
          setSignedUrl(signedUrlData.signedUrl);
        }
      } catch (err) {
        console.error("Error fetching article:", err);
        setError("Failed to load article");
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return (
      <PageWrapper>
        <LoadingSpinner />
      </PageWrapper>
    );
  }

  if (error || !article) {
    return (
      <PageWrapper>
        <ErrorMessage>{error || "Article not found"}</ErrorMessage>
        <BackButton to="/articles">Back to Articles</BackButton>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <ArticleContainer>
        <BackButton to="/articles">← Back to Articles</BackButton>
        <Title>{article.title}</Title>
        {signedUrl && <Image src={signedUrl} alt={article.title} />}
        <Content dangerouslySetInnerHTML={{ __html: article.content }} />
        {article.references && (
          <References>
            <ReferencesTitle>References</ReferencesTitle>
            <div dangerouslySetInnerHTML={{ __html: article.references }} />
          </References>
        )}
      </ArticleContainer>
    </PageWrapper>
  );
};

export default ArticleView;
