import React, { useState, useEffect } from "react";
import styled from "styled-components";
import cover2 from "../../assets/videos/cover2.mp4";
import cover3 from "../../assets/images/cover3.mp4";
import { Text, Modal } from "@mantine/core";
import ContactUs from "../../components/contactUs";
import Information from "../../components/information";
import Services from "../../components/services";
import Founders from "../../components/founders";
import Testimonials from "../../components/testimonials";
import Whyus from "../../components/whyus";
import Brands from "../../components/brands";
import { TypeAnimation } from "react-type-animation";

const BackgroundWrapper = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden; /* Ensures the video stays contained */
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the area without distorting */
  z-index: -2; /* Keep the video behind the content */
  overflow: hidden;
`;

// Add this new styled component for the overlay
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.58) 100%
  );
  z-index: -1;
`;

// Styled component for responsiveness using Flexbox
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  height: 100vh;
  text-align: center;
  z-index: 1;
`;

const AnimatedText = styled(TypeAnimation)`
  font-family: "Paris Avenue";
  font-size: 3rem;
  color: white;
  margin: 0;

  @media (max-width: 700px) {
    font-size: 2rem;
  }
`;

const Landing = () => {
  const [modalOpened, setModalOpened] = useState(false);

  // Effect to automatically open modal when the page loads
  // useEffect(() => {
  //   setModalOpened(true);
  // }, []);
  return (
    <div>
      <BackgroundWrapper>
        <VideoBackground src={cover2} autoPlay loop muted playsInline />
        <Overlay />
        <FlexContainer>
          <AnimatedText
            sequence={["Embark,"]}
            wrapper="h1"
            speed={5}
            cursor={false}
            style={{
              display: "inline-block",
              fontSize: "4rem",
              marginBottom: "-2rem",
            }}
          />
          <AnimatedText
            sequence={[
              "Key to new doors.",
              1000,
              "Key to your future.",
              1000,
              "Key to open possibilities.",
            ]}
            wrapper="h1"
            speed={2}
            repeat={Infinity}
            cursor={false}
            style={{
              display: "inline-block",
              marginBottom: "5rem",
            }}
          />
        </FlexContainer>
      </BackgroundWrapper>
      <Information id="intro" />
      {/* <Founders id="founders" /> */}
      <Services id="services" />
      <Brands id="brands" />
      <Testimonials id="testi" />
      <Whyus id="whyus" />
      <ContactUs id="contactus" />

      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        centered
        withCloseButton
        size="lg" // Adjust the size as needed
        overlayBlur={3}
        overlayColor="rgba(0, 0, 0, 0.6)" // Semi-transparent overlay
        transition="fade"
        transitionDuration={300}
        styles={{
          modal: {
            borderRadius: "20px",
            background: "#fff", // Set modal background color
          },
        }}
      >
        {/* Embed ContactUs component */}
        <ContactUs />
      </Modal>
    </div>
  );
};

export default Landing;
